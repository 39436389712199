
const Tech = props => {
	return (
<section>
<h1 id='techniques--technologies'>Techniques &amp; Technologies</h1>
<p>The namecaster application makes use of the following techniques and technologies:</p>
<h3 id='client'>Client</h3>
<ul>
<li>React.</li>
<li>MUI user interface library.</li>
<li>MUI custom theming.</li>
<li>immerReducer.</li>
<li>Dynamic assignment in immerReducer function.</li>
<li>useContext combined with useState for form state management.</li>
<li>Scroll-to-top web page button using state.</li>
<li>Copy-to-clipboard custom MUI icon button component with customized MUI snackbar.</li>
<li>Exported clipboard text is column-aligned and platform agnostic.</li>
<li>Flexible custom dual-range slider component.</li>
<li>Custom MUI Alert component with component property.</li>
<li>SPA architecture using tabbed Routes and NavLink.</li>
<li>Page state maintained when switching SPA tabs.</li>
<li>Any tab page accessible directly via a URL.</li>
<li>Axios client library for get/post calls to the server.</li>
</ul>

<h3 id='server'>Server</h3>
<ul>
<li>Fastify server using fastify-sqlite, fastify-cors, and deasync libraries.</li>
<li>SQLite3 database.</li>
<li>Reverse proxy (NGINX to Fastify) for REST routes.</li>
<li>pm2 server monitoring for automatic restarts if server fails or host reboots.</li>
<li>Server test mode including delayed responses and empty responses.</li>
</ul>

<h3 id='shared'>Shared</h3>
<ul>
<li>Snappy performance via promises client-side and async/await &amp; promises server-side.</li>
<li>Rigorous error management.</li>
<li>Request body/form validation using ajv and shared JSON Schema schema file.</li>
</ul>		
</section>
	)
}

export default Tech
