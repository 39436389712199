import Container from '@mui/system/Container'

const Notes = props => {
	return (
		// see index.css for h3 styling
		<Container class='notes-container'>
			<h1 >Some important notes about generated names and occupations.</h1>
			<h3>Disclaimer</h3>
			<p>Any resemblance or similarity between generated names &amp; occupations and actual people is purely coincidental.</p>
			<h3>Names</h3>
			<p>
				First, a note about gender and sex. The census names are labeled "male" and "female", and
				do not differentiate between gender and sex. This is because there were no fields on the 2010 
				census forms to distinguish between the two.
			</p>
			<p>
				Second, the generated names might sometimes seem a little weird. This is because the names are  
				taken directly from the 2010 US Census dataset, and so there are some important things to note:
			</p>
			<ul>
				<li>
					Names start with a capital A-Z, followed by the letters a-z. So, no accented characters, 
					mixed-case names, apostrophes, hyphens or spaces. Looking for O'Malley or del Toro? 
					You'll only find Omalley and Deltoro (these are real examples).
				</li>
				<li>
					Some of the male names are female, and some of the female names are male, so don't be surprised if 
					a male name comes back as Mary or a female name comes back as John (these are real examples). 
					This isn't a mistake; it merely reflects the reality of how some babies were named.
				</li>
				<li>
					A large number of first and last names &mdash;mostly foreign and ethnic&mdash; are missing. 
					I know several people, male and female, with foreign or ethnic names who are American citizens
					and were over 18 when the census was taken, whose names do not appear in the dataset.
				</li>
			</ul>	
			<h3>Occupations</h3>
			<p>
				There are 4,575 occupations in the jobs database table. They are mostly taken from a Bureau of Labor Statistics dataset, 
				with a tiny number hand-added by me. Job titles can sometimes be a little strange, and there are some obvious 
				ones missing (e.g. restauranteur, cantor, gaffer, anything with "tennis" in it). There is nothing I can do about
				that other than to occasionally update the occupations dataset by hand.
			</p>
			<h3>Conclusion: inspiration is the key</h3>
			<p>
				The generatred names and occupations are meant to be inspirational. If you <em>need</em> a tennis pro 
				in your story, a random occupation generator isn't going to put the idea in your head; you already know 
				you need one. Consider also mixing and matching first names, last names, and occupations from the 
				randomly-generated data. I find myself doing that more often than not with very satisfying results.
			</p>
		</Container>
	)
}

export default Notes
