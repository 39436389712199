import {useState,useEffect} from 'react'

import CssBaseline from '@mui/material/CssBaseline'
import Container from '@mui/material/Container'

import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { lime, purple } from '@mui/material/colors';

// smooth scroll sim for Safari
// from: https://github.com/iamdustan/smoothscroll via 
// https://stackoverflow.com/questions/56011205/is-there-a-safari-equivalent-for-scroll-behavior-smooth
import smoothscroll from 'smoothscroll-polyfill';

import Header from './components/Header'
import NavBar from './components/NavBar'
import Footer from './components/Footer'

// Contains the styling for the scroll-to-top button. See further below for details.
import './ScrollToTop.css'

const theme = createTheme({
	// typography: {
	// 	tab: {
	// 	  fontSize: '2rem',
	// 	},
	//   },	
	// palette: {
	// 	primary: purple,
	// 	secondary: purple,
	// }
})


function App() {

	/*-----------------------------------------------------------------------*/
	// Scroll-to-top button based on:
	//	https://www.kindacode.com/article/how-to-create-a-scroll-to-top-button-in-react/

	// We keep all the scroll-to-top stuff here because it's used app-wide, and also
	// because creating a component for the scroll-to-top button is overkill.

	// whether or not the scroll-to-top button is visible
	const [showScrollButton, setShowScrollButton] = useState(false);

	// track window scrolling to see if we need to show the scroll-to-top button
	useEffect(() => {

		// install our scroll-to-top button handler
		window.addEventListener("scroll", () => {
			if (window.scrollY > 300) {
				setShowScrollButton(true);
			} else {
				setShowScrollButton(false);
			}
		});

		// now set up our smooth scroll for Safari shim
		// kick off the polyfill!
		smoothscroll.polyfill();

	}, []);

	// This function is the click handler for the scroll-to-top button, and scrolls the window to the top.
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth' // Safari needs a JS shim for this as it does not support the behavior natively
		});
	};
  	/*-----------------------------------------------------------------------*/

	return (
		<>
		{/* CssBaseLine is a CSS reset for MUI */}
		<ThemeProvider theme={theme}>

		<CssBaseline />
		<Container
			sx = {{
				backgroundColor:'rgb(250, 170, 80)', 
				width: '960px',
				minHeight: '100vh',
				padding: '4rem !important',
				paddingBottom: '1.1rem !important',
				borderLeft: '8px solid goldenrod',
				borderRight: '8px solid goldenrod',
			}}
		>
			{/* 
				Our scroll-to-top button. The unicode is for the up arrow symbol.
				The button is only visible when the window has scrolled down by a 
				certain number of pixels as defined in useEffect() above.
			*/}
			{showScrollButton && (<button onClick={scrollToTop} className="scroll-to-top">&#10140;</button>)}
			{/* If using below commented-out arrow, remove the "rotate -90deg" in ScrollToTop.css */}
			{/* {showScrollButton && (<button onClick={scrollToTop} className="scroll-to-top">&#8679;</button>)} */}
			<Header />
			<NavBar /> 
			<Footer />
		</Container>
		</ThemeProvider>
		</>
  )
}

export default App
