import Avatar from '@mui/material/Avatar'
import cogsley from '../assets/images/scrlogo.png'

const Footer = props => {
	return (
		<footer
			style = {{
				marginTop: '2rem',
				paddingTop: '1rem',
				borderTop: '1px solid black',
				textAlign: 'center',
				fontFamily: 'monospace',
				fontSize: '0.9rem',
				lineHeight: 1
			}}
		>
			<Avatar
				alt='The see code run logo: a running stick figure with a cog for a head,'
				src={cogsley}
				sx={{ width: 32, height: 32, margin: '0 auto'}}
			/>
			&copy; 2025 Charles E. Friedman<br/>
			A <a href='https://seecoderun.com'>seecoderun</a> project.<br/>
			This site does not use cookies.
		</footer>
	)
}

export default Footer